'use client';

import { Form } from 'antd';
import { FormInstance, Rule } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import cn from 'classnames';
import React, { useState } from 'react';

import { Checkbox } from '@/app/_types/components/checkbox';
import { Dropdown } from '@/app/_types/components/dropdown';
import { InputM2AUnion } from '@/app/_types/components/form';
import { Input } from '@/app/_types/components/input';
import { RadioButton } from '@/app/_types/components/radio-button';
import { Textarea } from '@/app/_types/components/textarea';

import CheckBoxCustom from '../checkbox/CheckBoxCustom';
import FloatInput from '../float-input/FloatInput';
import FloatSelect from '../float-select/FloatSelect';
import FloatTextarea from '../float-textarea/FloatTextarea';
import RadioCustom from '../radio/RadioCustom';

type Props = {
  form: FormInstance;
  input: InputM2AUnion;
  value?: string;
  onChange?: () => void;
};

type InputConfig = {
  component: React.ReactNode;
  name: string;
  errorMessage: string | null | undefined;
  isRequired: boolean;
  isVisible: boolean;
  isFullWidth: boolean;
  help: string;
  options: { label: string; value: string }[];
  dependencies: string[];
  rules: Rule[];
  valuePropName: string;
};

const InputWrapper = (props: InputConfig) => {
  return (
    <Form.Item
      name={props.name}
      className={cn('customInput', props.isFullWidth && 'full-width')}
      rules={props.rules}
      hasFeedback
      help={props.help}
      hidden={!props.isVisible}
      valuePropName={props.valuePropName}
    >
      {props.component}
    </Form.Item>
  );
};

const FormInput = ({ form, input, value }: Props) => {
  const [selectValue, setSelectValue] = useState<string | undefined>(undefined);

  const getInputConfig = (
    getFieldValue: (name: any) => StoreValue,
  ): InputConfig => {
    const config: InputConfig = {
      component: null,
      name: '',
      errorMessage: null,
      isRequired: false,
      isVisible: true,
      isFullWidth: input.item.full_width,
      help: '',
      options: [],
      dependencies: [],
      rules: [],
      valuePropName: 'value',
    };

    if (input.collection === 'input') {
      const item = input.item as Input;

      config.name = item.name;
      config.errorMessage = item.error_message;
      config.isRequired = item.required;
      config.help = form?.getFieldError(item.name)?.length
        ? form.getFieldError(item.name).join('\n')
        : item.helper_text || '';

      if (item.hidden_by_default) {
        const oneConditionMet = item.render_conditions?.reduce(
          (accumulator, condition) => {
            config.dependencies.push(condition.field);
            return (
              getFieldValue(condition.field) === condition.value || accumulator
            );
          },
          false,
        );

        config.isVisible = !!oneConditionMet;
        config.isRequired &&= config.isVisible;
      }
      config.component = (
        <FloatInput
          label={item.label || ''}
          name={config.name}
          value={value || ''}
          required={config.isRequired}
        />
      );
    } else if (input.collection === 'dropdown') {
      const item = input.item as Dropdown;

      config.name = item.name;
      config.errorMessage = item.error_message;
      config.isRequired = item.required || false;
      config.help = form.getFieldError(item.name)?.length
        ? form.getFieldError(item.name).join('\n')
        : item.helper_text || '';
      config.options = item.options.map((optionM2A) => {
        const item = optionM2A.item;

        return {
          label: item.Label,
          value: item.Label,
        };
      });
      if (item.hidden_by_default) {
        const oneConditionMet = item.render_conditions?.reduce(
          (accumulator, condition) => {
            config.dependencies.push(condition.field);
            return (
              getFieldValue(condition.field) === condition.value || accumulator
            );
          },
          false,
        );

        config.isVisible = !!oneConditionMet;
        config.isRequired &&= config.isVisible;
      }
      config.component = (
        <FloatSelect
          label={item.label || ''}
          name={config.name}
          options={config.options}
          onChange={(value) => setSelectValue(value)}
          value={selectValue}
          placeholder={item.placeholder_text || undefined}
          required={config.isRequired}
        />
      );
    } else if (input.collection === 'textarea') {
      const item = input.item as Textarea;

      config.name = item.name;
      config.errorMessage = item.error_message;
      config.isRequired = item.required || false;
      config.help = form.getFieldError(item.name)?.length
        ? form.getFieldError(item.name).join('\n')
        : item.helper_text || '';
      if (item.hidden_by_default) {
        const oneConditionMet = item.render_conditions?.reduce(
          (accumulator, condition) => {
            config.dependencies.push(condition.field);
            return (
              getFieldValue(condition.field) === condition.value || accumulator
            );
          },
          false,
        );

        config.isVisible = !!oneConditionMet;
        config.isRequired &&= config.isVisible;
      }
      config.component = (
        <FloatTextarea
          label={item.label || ''}
          name={config.name}
          value={value || ''}
          required={config.isRequired}
        />
      );
    } else if (input.collection === 'radio_button') {
      const item = input.item as RadioButton;

      config.name = item.name;
      config.errorMessage = item.error_message;
      config.isRequired = item.required || false;
      config.help = form.getFieldError(item.name)?.length
        ? form.getFieldError(item.name).join('\n')
        : item.helper_text || '';
      config.component = (
        <RadioCustom
          label={item.label || ''}
          name={config.name}
          value={value || ''}
          required={config.isRequired}
        />
      );
    } else if (input.collection === 'checkbox') {
      const item = input.item as Checkbox;

      config.name = item.name;
      config.errorMessage = item.error_message;
      config.isRequired = item.required || false;
      config.help = form.getFieldError(item.name)?.length
        ? form.getFieldError(item.name).join('\n')
        : '';
      config.valuePropName = 'checked';
      config.component = (
        <CheckBoxCustom
          label={item.label || ''}
          name={config.name}
          required={config.isRequired}
          description={item.helper_text || ''}
          checked={value === 'true'}
        />
      );
      if (config.isRequired) {
        config.rules.push({
          validator: (_, value) =>
            value
              ? Promise.resolve()
              : Promise.reject(
                  new Error(config.errorMessage || 'Please select'),
                ),
        });
      }
    }

    config.rules.push({
      required: config.isRequired,
      message: config.errorMessage || 'Required',
    });

    return config;
  };

  const initialConfig = getInputConfig(form?.getFieldValue);

  return (
    <Form.Item
      noStyle
      dependencies={initialConfig.dependencies}
      shouldUpdate={(_prevValues, _nextValues, _info) => {
        // re-render when the field has an error
        return form.getFieldError(initialConfig.name)?.length > 0;
      }}
    >
      {({ getFieldValue }) => {
        // when the dependencies change, or the field has an error
        // re-evaluate the config and re-render the input
        const newConfig = getInputConfig(getFieldValue);

        return <InputWrapper {...newConfig} />;
      }}
    </Form.Item>
  );
};

export default FormInput;
