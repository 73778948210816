import { PlusOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import cn from 'classnames';
import React from 'react';

import './FloatSelect.scss';

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  mode?: 'multiple' | 'tags' | undefined;
  required?: boolean;
  value: string | undefined;
  options: Array<{ value: string; label: string }>;
  onChange?: (value: string) => void;
  allowClear?: boolean;
};

const FloatSelect = ({
  label,
  name,
  placeholder,
  mode,
  required,
  onChange,
  value,
  options,
  allowClear,
  ...otherProps
}: Props) => {
  const requiredMark = required ? <span className="text-error">*</span> : null;

  return (
    <div
      className={cn(
        'float-select-label',
        label && 'with-label',
        required && 'with-label',
      )}
    >
      <label htmlFor={name} className="label">
        {label} {requiredMark}
      </label>
      <Select
        id={name}
        onChange={onChange}
        showSearch
        options={options}
        suffixIcon={<PlusOutlined />}
        className="float-select"
        value={value}
        placeholder={placeholder ? placeholder : 'Enter ' + label}
        mode={mode && mode}
        {...otherProps}
        allowClear={allowClear}
      />
    </div>
  );
};

export default FloatSelect;
