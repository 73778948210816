import { Input } from 'antd';
import cn from 'classnames';
import React from 'react';

import './FloatInput.scss';

type Props = {
  label?: string;
  value: string;
  name: string;
  placeholder?: string;
  type?: 'text' | 'number' | 'email' | 'password' | 'textarea';
  required?: boolean;
  onChange?: (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
  ) => void;
  children?: React.ReactNode;
  maxLength?: number;
};

const FloatInput = ({
  label,
  value,
  name,
  placeholder,
  type = 'text',
  required,
  onChange,
  maxLength,
  ...rest
}: Props) => {
  const requiredMark = required ? <span className="text-error">*</span> : null;

  return (
    <div className={cn('float-label', label && 'with-label')}>
      <label htmlFor={name} className="label">
        {label} {requiredMark}
      </label>
      {type === 'textarea' ? (
        <Input.TextArea
          onChange={onChange}
          className="float-input"
          name={name}
          defaultValue={value}
          rows={4}
          placeholder={placeholder ? placeholder : 'Enter ' + label}
          {...rest}
        />
      ) : (
        <Input
          onChange={onChange}
          className="float-input"
          name={name}
          type={type}
          defaultValue={value}
          maxLength={maxLength}
          placeholder={placeholder ? placeholder : 'Enter ' + label}
          {...rest}
        />
      )}
    </div>
  );
};

export default FloatInput;
