import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';

import Styles from './CheckBoxCustom.module.scss';

type Props = {
  label?: string;
  description?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
};

const CheckBoxCustom = ({
  label,
  description,
  name,
  disabled,
  required,
  indeterminate,
  checked,
  onChange,
  ...rest
}: Props) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    if (onChange) {
      onChange(e);
    }
  };

  const requiredMark = required ? <span className="text-error">*</span> : null;

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      className={Styles.customCheckbox}
      disabled={disabled}
      name={name}
      indeterminate={indeterminate}
      {...rest}
    >
      <div className={Styles.customCheckboxLabelBox}>
        {label && (
          <div className={Styles.customCheckboxLabel}>
            {label} {requiredMark}
          </div>
        )}
        {description && (
          <div className={Styles.customCheckboxDescription}>{description}</div>
        )}
      </div>
    </Checkbox>
  );
};

export default CheckBoxCustom;
