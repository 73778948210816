import { Input } from 'antd';
import cn from 'classnames';
import React from 'react';

import './FloatTextarea.scss';

type Props = {
  label?: string;
  value: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  children?: React.ReactNode;
  maxLength?: number;
  row?: number;
};

const { TextArea } = Input;

const FloatTextarea = ({
  label,
  value,
  name,
  placeholder,
  required,
  onChange,
  maxLength,
  row = 6,
  ...rest
}: Props) => {
  const requiredMark = required ? <span className="text-error">*</span> : null;

  return (
    <div
      className={cn(
        'float-label',
        label && 'with-label',
        required && 'with-label',
      )}
    >
      <label htmlFor={name} className="label">
        {label} {requiredMark}
      </label>
      <TextArea
        onChange={onChange}
        className="float-input"
        name={name}
        rows={row}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder ? placeholder : 'Enter ' + label}
        {...rest}
      />
    </div>
  );
};

export default FloatTextarea;
